<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <div class="main-content" :data="sidebarBackground">
      <div class="main-left">
        <div style="background-color: #172b4d;">
          <!-- padding-bottom: 20px -->
          <nav class="navbar navbar-expand-lg navbar-light">
            <a href="/" class="navbar-brand router-link-active">
              <img
                src="/img/brand/logo-gxp1.png"
                alt="..."
                class="navbar-brand-img"
                style="width: 98px; max-height: 250px; margin-left: 18px"
              />
            </a>
            <a href="/" class="nav-item nav-link nav-item-client-logo">
            <img
                :src="
                  authCookie.orgs[0].logo && authCookie.orgs[0].logo.length > 0
                    ? authCookie.orgs[0].logo
                    : '/img/brand/client_logo.png'
                "
                alt="..."
                class="navbar-brand-img client-logo"
                id="uploadlogo"
              />
            </a>
            <div class="nav-menu">
              <ul class="navbar-nav">
                <!-- style="padding-left: 50px" -->
                <li class="nav-item">
                  <a
                    href="/"
                    class="nav-link nav-item active header-home"
                    >Home</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    href="/usersList"
                    class="nav-link nav-item active header-home"
                    >Users</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    href="/departmentList"
                    class="nav-link nav-item active header-home"
                    >Department</a
                  >
                </li>
                <div class="navbar-nav header-user-name">
                  <!-- <div>
                    <select class="department-dropdown"
                      @change="onChangeDepartment($event)"
                      v-model="selectedDepartment">
                      <option value="Department 1">Department 1</option>
                      <option value="Department 2">Department 2</option>
                      <option value="Department 3">Department 3</option>
                    </select>
                  </div> -->
                <select
                  class="department-dropdown"
                  @change="onChangeDepartment($event)"
                  v-model="selectedDepartment"
                >
                <!-- <option value="default" selected>default</option> -->
                  <option
                    v-for="option in departmentList"
                    :key="option.departmentID"
                    :value="option.departmentID"
                  >
                    {{ option.departmentName }}
                  </option>
                </select>
                </div>
              </ul>
              <div class="navbar-nav down-arrow">
                  <p @click="onDownArrowClick()" class="arrowclick">Project Overview
                    <i class="fa fa-arrow-down toggle" style="display:block;" id="downarrow"></i>
                    <!-- <i class="fa fa-arrow-up" style="display:block;" v-if="showTimeLine"></i> -->
                  </p>
              </div>
              <div v-if="this.authCookie.departments && this.authCookie.departments[0]"
              class="department-dashboard-name">
              {{ this.authCookie.departments[0].departmentName }}
              </div>
              <form class="form-inline header-search search-center" style="display: none;">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                  />
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-secondary navbar-search-btn"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>
              <!-- <div class="notification-bell search-center" @click="toggleNotifi()">
                <img src="/img/brand/bell.png" alt="" class="bell-img"> 
                <span class="notification-badge" v-if="this.notifyCount > 0">{{ notifyCount }}</span>
              </div> -->
              <div class="notifi-box" id="box">
                <h2 class="notifi-text">Notifications <span class="notifi-message">{{ notifyCount.length }}</span></h2>
                <div class="notifi-item">
                  <div v-for="(notification, index) in notificationTempList" :key="index">
                    <p @click="onMessageclick(notification)" class="text" v-if="(notification.type == 'document') || (notification.type == 'pdf') || (notification.type == 'flowChart') ||
                        (notification.type == 'forms')  || (notification.type == 'notepad') || (notification.type == 'sampleManagement') || (notification.type == 'equipment') || (notification.type == 'reagent') || 
                        (notification.type == 'stickyNotes') || (notification.type == 'sharedNotes') || (notification.type == 'images') ||
                        (notification.type == 'files') || (notification.type == 'videos') || (notification.type == 'deviation') || (notification.type == 'capa') || (notification.type == 'event')
                        || (notification.type == 'user') || (notification.type == 'department') || (notification.type == 'projectOverview')">
                      <b class="text-bold">{{ notification.userName}}</b>  {{ notification.message }} 
                      <br />
                      <span>{{ formatDate(notification.createdDate) }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="navbar-nav header-user-name navbar-right">
                <base-dropdown
                  title-classes="btn btn-secondary"
                  :title="authCookie.userName"
                >
                  <a class="dropdown-item" href="/" @click="changePwd"
                    >Change Password</a
                  >
                  <a class="dropdown-item" style="cursor: pointer" @click="uploadLogo()"
                    >Upload Logo</a
                  >
                </base-dropdown>
                <a
                  href="/"
                  class="nav-item nav-link header-logout"
                  @click="logout"
                  >Logout</a
                >
                <br />
                <!-- <home-chat v-if="chatbox"></home-chat> -->
                <div class="search-center">
                  <div v-if="showSlider">
                    <transition name="modal">
                      <div class="modal-mask">
                        <div class="modal-wrapper">
                          <div class="modal-dialog1">
                            <div class="modal-content">
                              <div class="modal-header">
                                {{ " User Manual Slide Header " }}
                                <button
                                  type="button"
                                  class="close"
                                  @click="showSlider=false"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div class="slides">
                                  <transition-group 
                                    name="slide"
                                    mode="out-in"
                                    enter-class="slide-in"
                                    leave-class="slide-out"
                                    enter-active-class="animated slide-in-active"
                                    leave-active-class="animated slide-out-active"
                                  >
                                    <div
                                      v-for="index in slides"
                                      :key="index"
                                    >
                                    <div v-if="index == active">
                                      Slide {{ index }}
                                      </div>
                                    </div>
                                  </transition-group>
                                    </div>
                                    <span
                                      class="prev"
                                      @click="moveSlide(-1)"
                                    >
                                      <i class="fa fa-chevron-left" aria-hidden="true"></i>
                                    </span>
                                    <span
                                      class="next"
                                      @click="moveSlide(1)"
                                    >
                                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                    </span>
                                    <ul class="dots">
                                      <li id="list-dots"
                                        v-for="(dot, index) in slides" :key="index"
                                        :class="{ active: ++index === active }"
                                        @click="jumpSlide(index)"
                                      ></li>
                                    </ul>
                                <br />
                                <button
                                  class="btn float-right btn-default btn-sm m-2"
                                  @click="showSlider = false"
                                >Close</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                  
                  <!-- <div id="overlay" @click="closeSlideShow()">
                    <div id="text">Hello World</div>
                  </div> -->
                  <div class="user-manual" @click="slideShowUsermanual()">User Manual</div>
                  <div class="chat-btn"><a href="/HomeChat" class="chat-text">Ask AI</a></div>
                  <!-- <a href="/HomeChat"><img src="/img/brand/chat-icon.png" alt="..." style="width: 40px; height: 40px; margin: 10px 10px 0 0; cursor: pointer;"/></a> -->
                  <div class="notification-bell" @click="toggleNotifi()">
                    <img src="/img/brand/bell.png" alt="" class="bell-img"> 
                    <span class="notification-badge" v-if="this.notifyCount > 0">{{ notifyCount }}</span>
                  </div>
                  <div class="notification-bell">
                    <a href="/showDataTables"><i class='fas fa-envelope bell-img' style='font-size:24px;color:white;margin-left: 15px;'></i></a>
                  </div>
                </div>
              </div>
            </div>  
            <div class="hamburger" @click="toggleMobileMenu()">
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
            </div>
          </nav>
        </div>
        <div @click="toggleSidebar">
          <fade-transition :duration="200" origin="center top" mode="out-in">
            <!-- your content here -->
            <router-view></router-view>
          </fade-transition>
          <content-footer v-if="!$route.meta.hideFooter"></content-footer>
        </div>
      </div>
    </div>
    <!-- <div v-if="showNotificationModal" class="alert success">
        <span class="closebtn" @click="notificationMessageClose()">&times;</span>  
        <p>{{notificationTempList.description}}</p>
    </div> -->
    <!-- start upload-logo -->
    <div v-if="showUploadLogo">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Upload Logo
                  <button
                    type="button"
                    class="close"
                    @click="showUploadLogo = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    <input type="file" id="file" style="margin-left: 36px; margin-bottom: 10px;" ref="file" name="file-to-upload" v-on:change="handleFileUploads()">
                      <base-button
                          size="sm"
                          type="default"
                          style="margin-bottom: 10px;"
                          class="float-right1"
                          @click="addImage($event)"
                        >preview</base-button>
                  <p v-if="filespath"><img style="width:100px; height:100px; margin-bottom: 10px;" :src="filespath" alt=""></p>
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="displayUploadLogo()"
                  >
                    Ok
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showUploadLogo = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End upload-logo -->
  </div>
</template>

<script>
import axios from "../utils/axiosinstance";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      showSlider: false,
      showTimeLine: false,
      showDepartment: false,
      showUploadLogo: false,
      files: '', 
      filespath: '',
      logo: '',
      authCookie: null,
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      departmentList: null,
      departmentID: "",
      show: true,
      selectedDepartment: "",
      down: false,
      notifyCount: 0,
      notificationTempList: [],
      slides: 10,
      active: 1
    };
  },
  mounted() {
    this.getDepartmentList();
    this.listenSaveNotification();
    this.getNotificationCount();
    if(this.authCookie.departments && this.authCookie.departments[0]){
      const dashboards = document.querySelectorAll('.dashboard');
      for(var i=0; i< dashboards.length; i++){
        const colors = ["#ff000066", "#ff7f007a", "#ffff004f", "#00ff0059", "#0000ff54", "#4b008263", "#9400d366"];
        const color = colors[Math.floor(Math.random() * colors.length)];
        dashboards[i].style.setProperty('--beforeBack', color);
        const newDiv = document.createElement('div');
        newDiv.innerHTML = this.authCookie.departments[0].departmentName;
        newDiv.className = 'addDepartmentname';
        dashboards[i].append(newDiv);
      }
    }
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    let authObj;
    if (authCookieObj.startsWith("j:")) {
      authObj = JSON.parse(
        decodeURIComponent(
          this.getCookie("auth-token").substring(2).split(";")[0]
        )
      );
    } else {
      authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
    }
    this.authCookie = authObj;
  },
  methods: {
    moveSlide(amount) {
      let newActive
      const newIndex = this.active + amount
      if (newIndex > this.slides) newActive = 1
      if (newIndex === 0) newActive = this.slides
      this.active = newActive || newIndex
    },
    jumpSlide(index) {
      this.active = index
    },
    addSlide() {
      this.slides = this.slides + 1
    },
    removeSlide() {
      this.slides = this.slides - 1 
    },
    slideShowUsermanual(){
      // document.getElementById("overlay").style.display = "block";
      this.showSlider = true;
    },
    closeSlideShow(){
      document.getElementById("overlay").style.display = "none";
    },
    onDownArrowClick(){
      var arrow = document.getElementById("downarrow");
      let showTimeLine = this.$route.query.showTimeLine
      if(!showTimeLine) {
        window.location ="/dashboard?showTimeLine=true";
        arrow.classList.toggle("fa-arrow-up");
        //showTimeLine = true;
      } else {
        // arrow.classList.toggle("fa-arrow-down");
        window.location ="/dashboard";
        //showTimeLine = false;
      }
      // console.log(timeline);
      // this.$root.$on("timeLine", this.showTimeLine);
      // var ban = document.getElementById("banner-content");
      // var arrow = document.getElementById("downarrow");
      // ban.style.display === "none" ?
      // ban.style.display = "block":
      // arrow.classList.toggle("fa-arrow-up");
      // arrow.classList.toggle("fa-arrow-down");
      // ban.style.display = "none"
      // arrow.classList.toggle("fa-arrow-up");
      // arrow.classList.toggle("fa-arrow-down");
    },
    onMessageclick(item){
      let app = this;
      if(item.type == 'document' && item.status == 'batch'){
        window.location = "/golive";
        // app.$router.push({
        //   name: "batch",  
        //   params: { docid: item.objectId, docType: item.status }
        // });
      }
      else if(item.type == 'sharedNotes'){
        app.$router.push({
          name: "sharedNotes",  
        });
      }
      else if(item.type == 'stickyNotes'){
        app.$router.push({
          name: "stickyNotes",  
        });
      }
      else if(item.type == 'files'){
        app.$router.push({
          name: "files",  
        });
      }
      else if(item.type == 'videos'){
        app.$router.push({
          name: "videos",  
        });
      }
      else if(item.type == 'images'){
        app.$router.push({
          name: "images",  
        });
      }
      else if(item.type == 'sampleManagement'){
        app.$router.push({
          name: "sampleManagement",  
        });
      }
      else if(item.type == 'equipment'){
        app.$router.push({
          name: "equipmentList",  
        });
      }
      else if(item.type == 'reagent'){
        app.$router.push({
          name: "reagentList",  
        });
      }
      else if(item.type == 'deviation'){
        app.$router.push({
          name: "deviation",
        }).catch(()=>{});
      }
      else if(item.type == 'capa'){
        app.$router.push({
          name: "capa",  
        });
      }
      else if(item.type == 'event'){
        app.$router.push({
          name: "event",  
        });
      }
      else if(item.type == 'user'){
        app.$router.push({
          name: "usersList",  
        });
      }
      else if(item.type == 'department'){
        app.$router.push({
          name: "departmentList",  
        });
      }
      else if((item.type == 'document' && item.status == 'approved') || (item.type == 'document' && item.status == 'shared') || (item.type == 'document' && item.status == 'draft')){
        app.$router.push({
          name: "new",
          params: { docid: item.objectId, docType: item.status }
        });
      }
      else if((item.type == 'pdf' && item.status == 'approved') || (item.type == 'pdf' && item.status == 'shared')){
        window.location.href =
          "/newPdf?docid=" +
          item.objectId +
          "&docStatusType=" +
          item.status +
          "&isPdf=" +
          item.type + "&industryItemType=" +
          this.industryItemType +
          "&industryItemID=" +
          this.industryItemID;
      }
      else if((item.type == 'forms' && item.status == 'approved') || (item.type == 'forms' && item.status == 'shared')){
        window.location.href =
          "/newForms?docid=" +
          item.objectId +
          "&docStatusType=" +
          item.status +
          "&isForms=" +
          item.type;
      }
      else if((item.type == 'flowChart' && item.status == 'approved') || (item.type == 'flowChart' && item.status == 'shared')){
        window.location.href =
          "/newFlowchart?flowChartid=" +
          item.objectId +
          "&docStatusType=" +
          item.status+
          "&isFlowchart=" +
          item.type;
      }
      else if((item.type == 'notepad' && item.status == 'approved') || (item.type == 'notepad' && item.status == 'shared')){
        window.location.href =
          "/newNotepad?docid=" +
          item.objectId +
          "&docStatusType=" +
          item.status +
          "&isNotepad=" +
          item.type + "&industryItemType=" +
          this.industryItemType +
          "&industryItemID=" +
          this.industryItemID;
      }
      else if(item.type == 'flowChart' && item.status == 'batch'){
        window.location.href =
            "/flowChart?docStatusType="+ item.status +
            "&isFlowchart=" +
            item.type;
      }
      else if(item.type == 'forms' && item.status == 'batch'){
        window.location.href =
            "/forms?docStatusType="+ item.status +
            "&isForms=" +
            item.type;
      }
      else if(item.type == 'pdf' && item.status == 'batch'){
        window.location.href =
            "/pdf?docStatusType="+ item.status +
            "&isPdf=" +
            item.type;
      }
      else if(item.type == 'notepad' && item.status == 'batch'){
        window.location.href =
            "/Notepad?docStatusType="+ item.status +
            "&isNotepad=" +
            item.type;
      }
    },
    notificationMessageClose(){
      var close = document.getElementsByClassName("closebtn");
      var i;

      for (i = 0; i < close.length; i++) {
          close[i].onclick = function(){
          var div = this.parentElement;
          div.style.opacity = "0";
          setTimeout(function(){ div.style.display = "none"; }, 600);
          }
      }
    },
    saveNotification(notificationObject) {
      var app = this;
      console.log(notificationObject);
      axios
      .post("/api/notification", notificationObject)
      .then(function(response) {
      console.log(response);
      notificationObject = response.data.item;
      //app.notificationTempList = notificationObject;
      // app.showNotificationModal = true;
      // alert("notification Created Successfully!");
      console.log(app.notifyCount);
      app.notifyCount = app.notifyCount + 1;
      })
      .catch(function(error) {
      console.log(error);
      });
    },
    updateNotification(){
      let app = this;
        axios
          .put("/api/notificationupdate/organizations/" + this.authCookie.orgs[0].orgID + "?read=true")
          .then(function(response) {
            console.log(response);
            // app.notifyCount = response.data.count;
            app.notifyCount = 0;
          })
          .catch(function(error) {
            console.log(error);
          });
    },
    getNotificationCount() {
      let app = this;
      axios
      .get("/api/notification/countDocuments/organizations/" + this.authCookie.orgs[0].orgID + "?read=false")
      .then(function(response) {
        app.notifyCount = response.data.count;
      })
      .catch(function(error) {
      console.log(error);
      return app.notifyCount;
      })
    },
    getNotificationList(){
      let app = this;
      var notificationListURL =
      "/api/notification/organizations/" + this.authCookie.orgs[0].orgID;
      if( this.notificationTempList.status  == "batch")
        notificationListURL = "/api/notificationBatch/organizations/" + this.authCookie.orgs[0].orgID + "?status1=" + this.notificationTempList.status;
      axios
      .get(notificationListURL)
      .then((response) => {
          app.notificationTempList = response.data.items;
      })
      .catch((reason) => {
          console.log(reason);
          app.notificationTempList = [];
      }); 
    },
    listenSaveNotification(){
      this.$root.$on('listenNotification', (obj) => {
            this.saveNotification(obj);
        });
    },
    getDepartmentList(){
      var departmentListURL =
        "/api/department/organizations/" + this.authCookie.orgs[0].orgID;
      axios
        .get(departmentListURL)
        .then((response) => {
          console.log(response);
          if(this.authCookie.departments && this.authCookie.departments[0]){
          this.selectedDepartment = this.authCookie.departments[0].departmentID;
          }
          this.departmentList = response.data.items;
        })
        .catch((reason) => {
          console.log(reason);
          this.departmentList = [];
        });
    },
    toggleNotifi(){
      var box  = document.getElementById('box');
        if (this.down) {
          box.style.height  = '0px';
          box.style.opacity = 0;
          this.down = false;
        }else {
          box.style.height  = '180px';
          box.style.opacity = 1;
          // box.style.background-color = '#172b4d';
          this.down = true;
        }
      this.getNotificationList();
      this.updateNotification();
      // this.notifyCount = 0;
    },
    openNewWindow(){
      window.open("https://www.geeksforgeeks.org",
                    "_blank");
    },
    toggleMobileMenu(){
        const hamburger = document.querySelector(".hamburger");
        const navMenu = document.querySelector(".nav-menu");
        // hamburger.addEventListener("click", mobileMenu);
            hamburger.classList.toggle("active");
            navMenu.classList.toggle("active");
        const navLink = document.querySelectorAll(".nav-link");
        navLink.forEach((link) => {
          link.addEventListener("click", function() {
            hamburger.classList.remove("active");
            navMenu.classList.remove("active");
          });
        }); 
    },
    onChangeDepartment(event) {
      console.log(event);
      if (this.selectedDepartment)  {
        this.updateAuthCookieDepartment(this.selectedDepartment);
        // this.$root.$emit('onChangeDepartment', this.selectedDepartment);
        window.location ="/dashboard";
      }
    },
    updateAuthCookieDepartment (departmentID) {
      axios
          .put("/api/authenticate/department/"+departmentID+"/cookie")
        .then((response) => {
          console.log(response);
          // department =  response.data.item;
        })
        .catch((reason) => {
          console.log(reason);
        });
    },
    changePwd() {
      this.$router.push("/changePassword");
    },
    uploadLogo(){
      this.showUploadLogo = true;
    },
    displayUploadLogo(){
      let app = this;
      axios
      .put("/api/authenticate/addlogo", {
          logo: this.filespath,
        })
      .then(function(response) {
      console.log(response);
      app.authCookie.orgs[0].logo = response.data.item.logo;
      window.location ="/dashboard";
      })
      .catch(function(error) {
      console.log(error);
      });
      this.showUploadLogo = false;
      
    },
    handleFileUploads(){
      this.files = this.$refs.file.files[0]; //to store on ok
      // this.filespath = URL.createObjectURL(this.files); //to show
    },
    addImage(e){
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append('file', this.files);
      axios
          .post("/api/uploadWidgetFiles", formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          )
          .then(function(response) {
            app.filespath = "";
            var path = response.data.fileUrl;
            app.filespath = path.toString();
            alert("File Uploaded Successfully");
          })
          .catch(function(error) {
            console.log(error);
          });
    },
    logout() {
      document.cookie =
        "auth-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      // window.location = "/#/login";
      window.location = "/";
      // this.$router.push("/login");
      return false;
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    handleClick() {
      document.cookie =
        "auth-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      // return false;
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
  },
};
</script>
<style lang="scss">
.department-dashboard-name{
  margin-left: -93px;
  color: #fff;
  font-size: medium;
  text-transform: capitalize;
  font-weight: bold;
}
.arrowclick{
  font-size:16px;
  color:white;
  margin-left: 4rem;
  border-radius: 50%;
  background-color: #172b4d;cursor: pointer;
  padding: 5px;text-align:center;
}
.down-arrow{
  position: relative;
  top: 58px;
}
.chat-icon{
  border-radius: 20%;
  // position: fixed;
  // bottom: 0;
  // width: 100%;
}
.navbar-toggler{
  background-color: #fff !important;
}
.department-dropdown {
  background-color: #172b4d;
  color: white;
  border-color: #d3d3d336;
  // border: none;
  height: 35px;
  width: 120px;
}
.header-user-name {
  display: block;
  // height: 40px;
  position: relative;
  top: 30px;
}
.header-logout {
  position: relative;
  // font-size: 12px;
  text-align: right;
}
.text-green {
  letter-spacing: 3px;
  font-size: 20px;
}
.header-home {
  position: relative;
  top: 32px;
  padding-left: 50px;
}
.header-golive {
  position: relative;
  top: 32px;
}
.header-search {
  position: relative;
  top: 23px;
}
.navbar-search-btn {
  width: auto !important;
}

.nav-bar-main {
  //background-color: #08215d !important;
  color: white;
  left: -6px;
  top: 2px;
}
.navbar-light {
  //background-color: #08215d !important;
}
.nav-link {
}
.navbar-header {
  top: 60px;
}
.navbar-brand {
  margin-right: 0px !important;
}
.client-logo {
  border-radius: 0.375rem;
  max-height: 75px;
  position: absolute;
  // top: 3px;
  top: 15px;
  border: 0.1em solid #08215d;
  margin: 5px;
}
.nav-item-client-logo {
  width: 120px;
}
.btn-info,
.btn-info:hover {
  color: #08215d !important;
  // background-color: #a2ccf9 !important;
  border-color: #08215d !important;
}
.btn-info {
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  position: relative !important;
  text-transform: none !important;
  -webkit-transition: all 0.15s ease !important;
  transition: all 0.15s ease !important;
  letter-spacing: 0.025em !important;
  font-size: 0.775rem !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  font-weight: 500 !important;
  border: 1px solid #a2ccf9 !important;
  padding: 0.625rem 1.25rem !important;
  line-height: 1.5 !important;
  border-radius: 0.375rem !important;
  color: #172b4d !important;
  background-color: transparent !important;
  border-color: #dadce0 !important;
}
.btn-info:hover {
  border-color: #08215d !important;
}
.navbar-light .navbar-nav .nav-link {
  color: white !important;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
}
li {
    list-style: none;
}

a {
    text-decoration: none;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navbar-right {
    // left: 16rem;
    top: 4rem; 
    position: absolute; 
    right: 3rem;
}
.search-center {
    display: flex;
    // left: 14rem;
    position: relative;
    bottom: 13px;
}
.chat-btn {
  width: 70px;
  height: 30px;
  text-align: center;
  margin-top: 15px;
  margin-right: 10px;
  background: #00aff0;
  color: #fff;
  position: relative;
  border-radius: 50%;
}
.chat-text{
  color:#fff;
}
.chat-text:hover{
  color:#fff;
}
.chat-btn:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 7px;
  right: -18px;
  width: 30px;
  height: 30px;
  border-bottom-left-radius: 40px 35px;
  transform: translate(0px, -20px);
}
.chat-btn:before {
  content: "";
  position: absolute;
  top: 25px;
  height: 15px;
  border-right: 15px solid #00aff0;
  border-bottom-right-radius: 25px 25px;
  transform: translate(0, -4px);
}
.user-manual{
  margin-top: -20px;
  margin-right: -90px;
  color: #fff;
  cursor: pointer;
}
// #overlay {
//     position: fixed;
//     z-index: 4;
//     display: none;
//     width: 100%;
//     height: 130px;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: rgba(0,0,0,0.5);
//     z-index: 2;
//     cursor: pointer;
// }
// #text{
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     font-size: 50px;
//     color: white;
//     transform: translate(-50%,-50%);
//     -ms-transform: translate(-50%,-50%);
// }
// .user-manual:hover #overlay {
//   width: 100%;
// }
// .nav-item {
//     margin-left: 5rem;
// }


@media only screen and (max-width: 1024px) {
      .user-manual {
        margin-top: 20px;
        color: #fff;
        cursor: pointer;
        margin-right: 0px !important;
      }
      .down-arrow {
      position: relative;
      top: 40px;
      }
      .navbar-right {
        right: -2rem;
    }
    .search-center {
        display: block;
        top: -35px;
    }
    .header-logout {
      position: relative;
      // font-size: 12px;
      text-align: center;
    }
    .nav-menu {
        position: fixed;
        left: -100%;
        top: 6.5rem;
        flex-direction: column;
        background-color: #000;
        width: 100%;
        // border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
        z-index: 2;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 0.5px 0;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }
    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .header-user-name {
      display: block;
      position: relative;
      top: 30px;
    }
    .nav-link {
      color: #fff;
    }
    form.form-inline.header-search {
    display: none;
    }
    .notification-bell {
      cursor: pointer;
      // line-height: 60px;
      // margin-left: 3rem;
    }
}

.notification-bell {
	cursor: pointer;
	// margin-right: 50px;
	// line-height: 60px;
}
.notification-bell .notification-badge {
	background: #f00;
	padding: 4px;
	border-radius: 50%;
	color: #fff;
	vertical-align: top;
	margin-left: -16px;
}
.notification-bell .bell-img {
	display: inline-block;
	width: 25px;
	margin-top: 18px;
}
.notification-bell:hover {
	opacity: .7;
}
.notifi-box {
  background-color: #172b4d;
  border-radius: 11px;
	width: 300px;
  overflow: auto;
	height: 0px;
	opacity: 0;
  z-index: 5;
	position: absolute;
	top: 106px;
	margin-left: 40rem;
	transition: 1s opacity, 250ms height;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.notifi-box .notifi-text {
  // background-color: #172b4d;
  // position: fixed;
  // width: 285px;
  // z-index: 1;
	font-size: 14px;
	padding: 10px;
	// border-bottom: 1px solid #eee;
	color: #fff;
  text-align: center;
}
.notifi-box .notifi-text .notifi-message {
	color: #f00;
}
.notifi-item {
	display: block;
  background-color: #eee;
	border-bottom: 1px solid #eee;
	padding: 15px 5px;
	margin-bottom: 15px;
  // margin-top: 25px;
	cursor: pointer;
}
.notifi-item:hover {
	background-color: #eee;
}
.notifi-item img {
	display: block;
	width: 50px;
	margin-right: 10px;
	border-radius: 50%;
}
.notifi-item .text {
	padding: 10px;
  border-bottom: 1px solid #80808021;
  font-size: 12px;
}

.alert {
  padding: 20px;
  background-color: #172b4d;
  color: white;
  opacity: 1;
  width: 30%;
  float: right;
  transition: opacity 0.6s;
  margin-bottom: 15px;
}
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

b.text-bold {
  font-weight: bold;
  font-size: 14px;
}


.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-header {
  background-color: #dadce0;
}
.modal-dialog1 {
  max-width: 1000px !important;
  margin: 1.75rem auto;
}
// start popup-slider css //
$primary: #221e21;

.prev,
.next {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  border: 2px solid $primary;
  color: $primary;
  border-radius: 50%;
  margin-top: -60px;
  margin-left: auto;
  cursor: pointer;
  line-height: 48px;
  text-align: center;
  text-indent: -2px;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
  &:hover {
    background: $primary;
    color: #fff;
    transform: scale(1.2);
  }
  
  &:active {
    transform: translate(0, 3px) scale(1.2);
  }
}

.next {
  right: 0;
  margin-left: auto;
  margin-right: 25px;
  text-indent: 2px;
}

.dots {
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  bottom: 50px;
  margin-left: -55px;
  
  #list-dots {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: $primary;
    opacity: 0.2;
    display: inline-block;
    margin: 0 3px;
    cursor: pointer;
    transition: opacity 0.4s ease-in-out,width 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    
    &.active {
      width: 22px;
      opacity: 1;
    }
  }
}

.slides {
  font-size: 40px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  
  @media (min-width: 600px) {
    font-size: 80px;
  }
  
  // @media (min-width: 900px) {
  //   font-size: 140px;
  // }
  
  .animated {
    transition: all 400ms;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  
  .slide-in {
    opacity: 0;
    transform: translate(-40%, -50%);
  }
  
  .slide-in-active {
    transition-delay: 150ms;
  }
  
  .slide-out {
    opacity: 1;
  }
  
  .slide-out-active {
    opacity: 0;
    transform: translate(-60%, -50%);
  }
}

// end popup-slider css //
</style>