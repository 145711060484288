<template>
  <div class="main-content">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "home-layout",
  data() {
    return {};
  },
};
</script>
<style>
html,
body,
#app,
.main-content {
  height: 100%;
}
</style>
