<template>
  <div class="main-content">
    <div style="background-color: #172b4d;padding-bottom: 20px">
      <!-- <div>
        <a href="/" class="navbar-brand router-link-active">
          <img
            src="/img/brand/logo-gxp1.png"
            alt="..."
            class="navbar-brand-img custom-logo"
          />
        </a>
      </div> -->
      <!-- Wow -->
      
      <nav class="navbar navbar-expand-md navbar-light nav-bar-main">
        <div
          class="collapse navbar-collapse justify-content-between"
          id="navbarCollapse"
        >
          <div class="navbar-nav">
            <a href="/" class="nav-item nav-link nav-item-client-logo">
              <!-- <img
                :src=" (authCookie.orgs[0].logo && authCookie.orgs[0].logo.length > 0 ) ? authCookie.orgs[0].logo : 'img/brand/sample-logo.png' "
                alt="..."
                class="navbar-brand-img client-logo"
              />-->
            </a>
          </div>
          <div>
            <p class="login-heading">LIMS & Bioequivalence Protocol Management Software</p>
          </div>
          <div class="navbar-nav">
            <!-- <div class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Messages</a>
                        <div class="dropdown-menu">
                            <a href="#" class="dropdown-item">Inbox</a>
                            <a href="#" class="dropdown-item">Sent</a>
                            <a href="#" class="dropdown-item">Drafts</a>
                        </div>
            </div>-->
          </div>
          <!--<form class="form-inline header-search">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search" />
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary navbar-search-btn"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>-->

          <div v-if="!isMobile()" class="custom-header" style="display: none;">
            <span class="custom-pad">Free Trial</span>
              <button class="custom-pointers" v-on:click="show = !show">
                Login
              </button>
            <!--<a
              href="/"
              class="nav-item nav-link header-logout"
              @click="showLogin"
              >Login</a
            >-->
            <br />
            <!-- <span v-if="authCookie && authCookie.orgs[0].orgName">  {{ 'Org: ' + authCookie.orgs[0].orgName }} </span> -->
          </div>

        </div>
      </nav>
    </div>
    <!-- Header -->
    <!-- <div class="header py-7 py-lg-8">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Trushen Start 
        <div id="demo">
          <button v-on:click="show = !show">
            New Login
          </button>
          <transition name="fade">
            <div v-if="show">hello</div>
          </transition>
        </div>
        Trushen End -->
      <div
        class="row align-items-center justify-content-xl-between"
        style="display: flex"
      >
        <div class="col-md-7">
          <div
            v-if=" !isSharedPage"
            class="col-md-10"
            style="height: 420px"
          >
            <transition name="modal">
              <div class="modal-mask-login">
                <div class="modal-wrapper-login">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        Welcome, Please Sign In
                        <!-- <button
                      type="button"
                      class="close"
                      @click="showLoginModal=false"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button> -->
                      </div>
                      <div class="modal-body">
                        <div class="row justify-content-center">
                          <div class="card-login bg-secondary shadow-login border-0">
                            <div>
                              <form role="form">
                                <base-input
                                  class="input-group-alternative mb-3"
                                  placeholder="User Name"
                                  addon-left-icon="ni ni-hat-3"
                                  v-model="model.login.userName"
                                ></base-input>

                                <base-input
                                  class="input-group-alternative"
                                  placeholder="Password"
                                  id="myInput"
                                  type="password"
                                  addon-left-icon="ni ni-lock-circle-open"
                                  v-model="model.login.password"
                                  @keyup.enter="handleLogin"
                                ></base-input>
                                <div style="margin-left: 14px;">
                                <input type="checkbox" @click="showPassword()"><span style="font-size: 14px; margin-left: 5px;">Show Password</span>
                                </div>
                                <p id="errorMessage" style="color: red; font-size: 13px;"></p>
                                <p id="errorContact" style="color: red; font-size: 13px;"></p>
                                <base-checkbox class="custom-control-alternative">
                                  <span class="text-muted">Remember me</span>
                                </base-checkbox>
                                <div class="text-center">
                                  <base-button
                                    type="primary"
                                    class="my-4"
                                    v-on:click="handleLogin"
                                    >Sign in</base-button
                                  >
                                </div>
                              </form>
                            </div>
                          </div>
                          <div class="row mt-3" style="display: inline">
                            <!-- <div class="col-12" style="margin: 15px;">
                          <a href="#" class="text-light">
                            <small>Forgot password?</small>
                          </a>
                        </div> -->
                            <!-- <div class="col-12" style="margin: 15px">
                          <router-link to="/register" class="text-light">
                            <small>Create new account</small>
                          </router-link>
                        </div> -->
                            <div class="col-12" style="margin: 15px;">
                              <a @click="launchRegister" class="text-light">
                                <small>Create new account</small>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div
            v-if="showRegisterModal && !isSharedPage"
            class="col-xl-8"
            style="height: 700px"
          >
            <transition name="modal">
              <div class="modal-mask-register">
                <div class="modal-wrapper-register">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        Welcome to Digital GxP! Please Register
                        <!-- <button
                        type="button"
                        class="close"
                        @click="showRegisterModal=false"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button> -->
                      </div>
                      <div class="modal-body">
                        <!-- <div class="row justify-content-center"> -->
                        <div class="justify-content-center">
                          <div class="card-register bg-secondary shadow-register border-0">
                            <div>
                              <form role="form">
                                <base-input
                                  class="input-group-alternative mb-3"
                                  placeholder="User Name"
                                  addon-left-icon="ni ni-hat-3"
                                  v-model="model.register.userName"
                                ></base-input>

                                <base-input
                                  class="input-group-alternative mb-3"
                                  placeholder="Email"
                                  addon-left-icon="ni ni-email-83"
                                  v-model="model.register.email"
                                ></base-input>
                                <p id="error"></p>
                                <!-- <p style="color: red;">Enter a valid company email address</p> -->
                                <base-input
                                  class="input-group-alternative"
                                  placeholder="Password"
                                  type="password"
                                  addon-left-icon="ni ni-lock-circle-open"
                                  v-model="model.register.password"
                                ></base-input>

                                <base-input
                                  class="input-group-alternative"
                                  placeholder="Company"
                                  addon-left-icon="ni ni-lock-circle-open"
                                  v-model="model.register.orgName"
                                ></base-input>

                                <div class="text-muted font-italic">
                                  <small>
                                    password strength:
                                    <span class="text-success font-weight-700"
                                      >strong</span
                                    >
                                  </small>
                                </div>

                                <div class="row my-4">
                                  <div class="col-12">
                                    <base-checkbox
                                      class="custom-control-alternative"
                                    >
                                      <span class="text-muted">
                                        I agree with the
                                        <a href="#!">Privacy Policy</a>
                                      </span>
                                    </base-checkbox>
                                  </div>
                                </div>
                                <div class="np-captcha-container">
                                  <div class="np-captcha" v-if="captchaCode && captchaCode.length">
                                    <div
                                      v-for="(capt, capt_index) in captchaCode"
                                      :key="capt_index"
                                      :style="{
                                        fontWeight: 800,
                                      }"
                                      class="np-captcha-character"
                                    >
                                      {{ capt }}
                                    </div>
                                  </div>
                                </div>
                                <div class="inline" @click="createCaptcha()">
                                    <i class="fa fa-sync"></i>
                                </div>
                                <input type="text" placeholder="Captcha" id="cpatchaTextBox" 
                                v-model="model.register.captcha"/>
                                <p id="key"></p>
                                <div class="text-center">
                                  <base-button
                                    type="primary"
                                    class="btn-register my-4"
                                    v-on:click="handleRegister"
                                    >Create account</base-button
                                  >
                                </div>
                              </form>
                            </div>
                          </div>
                          <div class="row mt-3" style="display: inline">
                            <!-- <div class="col-12" style="margin: 15px;">
                            <a href="#" class="text-light">
                              <small>Forgot password?</small>
                            </a>
                          </div> -->
                            <!-- <div class="col-12" style="margin: 15px">
                            <router-link to="/register" class="text-light">
                              <small>Create new account</small>
                            </router-link>
                          </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <!-- Page content -->
          <!-- <div class="container" style="display: inline-block">
            <router-view v-on:showLoginOnShared="showLoginOnShared"></router-view>
          </div> -->
        </div>
          <!--<div v-if="!isMobileandTab()">-->
            <div
              v-if="!isMobileandTab()"
              class="col-md-5"
              style="height: 420px"
            >
            <img src="/img/brand/signin.png" alt="..." style="height: 400px; width: 95%;"/>
            </div>        

      </div>
    <!-- start adreess location -->
    <div class="address-location">
      <img src="/img/brand/location-address.png" alt="Image..."/>
    </div>
    <!-- end adreess location -->

    <!-- Page content -->
    <!-- <div class="container mt--8 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div> -->

    <div class="container" style="display:none">
      <center>
      <div>
        <h1 class="custom-text text-4xl text-bold text-red-500">
          G{{ displayText.join("") }}
        </h1>
      </div>
            

      <!--<div class="typewriter">
        <h1>Go Paperless</h1>
      </div>-->
      <!--<h1 class="custom-text"> Go Paperless </h1>-->
      </center>
    </div>
    <div class="container" style="display:none">
      <div class="row align-items-center justify-content-xl-between">
      
        <div class="col-xl-1 footer-img img-1"></div>
        <div class="col-xl-1 footer-img img-2"></div>
        <div class="col-xl-1 footer-img img-3"></div>
        <div class="col-xl-1 footer-img img-4"></div>
        <div class="col-xl-1 footer-img img-5"></div>
        <div class="col-xl-1 footer-img img-6"></div>
        <div class="col-xl-1 footer-img img-7"></div>
        <div class="col-xl-1 footer-img img-8"></div>
        <div class="col-xl-1 footer-img img-9"></div>
        <div class="col-xl-1 footer-img img-10"></div>
        <div class="col-xl-2 footer-img img-11"></div>
      </div>
    </div>
    <div>
    
      
      <div v-if="isMobile()" class="row align-items-center justify-content-xl-between" style="margin: 0; background-color: black; display:none">
        <div class="custom-margin-mobile" style="">
          <h3 class="custom-size-mobile" style="">
            <span>SOP</span>
          </h3>
        </div>
        <div class="custom-margin-mobile" style="">
          <h3 class="custom-size-mobile" style="">
            <span>PROTOCOLS</span>
          </h3>
        </div>
        <div class="custom-margin-mobile" style="">
          <h3 class="custom-size-mobile" style="">
            <span>BATCH RECORDS</span>
          </h3>
        </div>
        <div class="custom-margin-mobile" style="">
          <h3 class="custom-size-mobile" style="">
            <span>FORMS</span>
          </h3>
        </div>
      </div>
      <div v-else class="row align-items-center justify-content-xl-between" style="margin: 0; background-color: black;display:none; ">
        <div class="custom-margin" style="">
          <h3 class="custom-size" style="">
            <span>SOP</span>
          </h3>
        </div>
        <div class="custom-margin" style="">
          <h3 class="custom-size" style="">
            <span>PROTOCOLS</span>
          </h3>
        </div>
        <div class="custom-margin" style="">
          <h3 class="custom-size" style="">
            <span>BATCH RECORDS</span>
          </h3>
        </div>
        <div class="custom-margin" style="">
          <h3 class="custom-size" style="">
            <span>FORMS</span>
          </h3>
        </div>
      </div>

      <div v-if="isMobile()" class="row align-items-center justify-content-xl-between" style="margin: 0; background-color: black;display:none;">
        <div class="custom-margin-mobile" style="">
          <h3 class="custom-size-mobile" style="">
            <span>ELN</span>
          </h3>
        </div>
        <div class="custom-margin-mobile" style="">
          <h3 class="custom-size-mobile" style="">
            <span>LOGBOOKS</span>
          </h3>
        </div>
        <div class="custom-margin-mobile" style="">
          <h3 class="custom-size-mobile" style="">
            <span>WORK ORDERS</span>
          </h3>
        </div>
        <div class="custom-margin-mobile" style="">
          <h3 class="custom-size-mobile" style="">
            <span>MANUALS</span>
          </h3>
        </div>
      </div>
      <div v-else class="row align-items-center justify-content-xl-between" style="margin: 0; background-color: black;display:none; ">
        <div class="custom-margin" style="">
          <h3 class="custom-size" style="">
            <span>ELN</span>
          </h3>
        </div>
        <div class="custom-margin" style="">
          <h3 class="custom-size" style="">
            <span>LOG BOOKS</span>
          </h3>
        </div>
        <div class="custom-margin" style="">
          <h3 class="custom-size" style="">
            <span>WORK ORDERS</span>
          </h3>
        </div>
        <div class="custom-margin" style="">
          <h3 class="custom-size" style="">
            <span>MANUALS</span>
          </h3>
        </div>
      </div>
 

      <!--<div
        class="row align-items-center justify-content-xl-between"
        style="margin: 0; background-color: white"
      >
        <div style="width: 100%; align-self: center">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
            "
          >
            <h2 style="font: var(--font_2)">GxP Industry Opinion</h2>
          </div>
          <div style="padding: 20px; width: 100%">
            <div
              style="display: flex; flex-direction: column; align-items: center"
            >
              <div class="row" style="width: 100%; margin: 10px">
                <div
                  style="
                    width: 33%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                  "
                >
                  <div
                    style="
                      width: 70%;
                      border-style: solid;
                      padding: 20px;
                      height: -webkit-fill-available;
                      min-height: 230px;
                      /*height: 300px;*/
                    "
                  >
                    <p style="font: var(--font_3); /*height: 160px*/">
                      "Digital GxP is the easy button to share and collaborate
                      to make it right on every step of your SOP"
                    </p>
                    <span style="font-size: 11px"
                      >Field Application Specialist, Equipment Vendor</span
                    >
                  </div>
                </div>
                <div
                  style="
                    width: 33%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      width: 70%;
                      border-style: solid;
                      padding: 20px;
                      height: -webkit-fill-available;
                      min-height: 230px;
                      /*height: 300px;*/
                    "
                  >
                    <p />
                    <p style="font: var(--font_3); /*height: 160px*/">
                      “I can finally make PDF or Word documents more
                      understandable.”
                    </p>
                    <span style="font-size: 11px"
                      >Training Manager, Mid-size Pharma</span
                    >
                  </div>
                </div>
                <div
                  style="
                    width: 33%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                  "
                >
                  <div
                    style="
                      width: 70%;
                      border-style: solid;
                      padding: 20px;
                      height: -webkit-fill-available;
                      min-height: 230px;
                      /*height: 300px;*/
                    "
                  >
                    <p />
                    <p style="font: var(--font_3); /*height: 160px*/">
                      “Long time due. This is the format and resource needed for
                      technical documentation”
                    </p>
                    <span style="font-size: 11px"
                      >Process Engineer, Big Pharma</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->

      <div v-if="isMobileandTab()" style="height:1300px; background-color: black">
        <div style="display: flex; flex-direction: column; align-items: center">
          <div style="align-self: center; background-color: black">
            <div style="width: 100%">
              <div class="row" style="width: 100%; margin: 10px">
                <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  font: var(--font_6);
                "
              >
                <div style="padding: 10px; color: whitesmoke">
                  <p style="font-size: 21px; font-weight: 400">
                    Please drop your email for Support
                  </p>
                  <p style="font-size: 21px; font-weight: 400">
                    <input
                      type="text"
                      v-model="emailForDemo"
                      maxlength="254"
                    /><button
                      id="submitEmailForDemo"
                      @click="dropEmailForDemo()"
                    >
                      Submit
                    </button>
                  </p>
                  <p
                    style="
                      font-size: 16px;
                      font: var(--font_8);
                      color: rgb(var(--color_16));
                    "
                  >
                    We will be in touch soon! Thanks!
                  </p>
                </div>
              </div>



                <div
                  style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  font: var(--font_9);
                "
              >
                <div style=" padding: 20px; color: whitesmoke">
                  <p style="font-size: 24px">Digital GxP</p>
                  <p style="font-size: 14px">
                    Paperless Digital SOP, Protocol, Batch Records, Forms,
                    Checksheets, Logbooks
                  </p>
                  <p style="font-size: 17px">About Us</p>
                  <p style="font-size: 14px">
                    With a combined industry experience of over 3 decades in
                    SaaS platforms & GxP industry, our aim is to digitalize and
                    drive towards pharma industry 4.0 starting with paperless
                    systems
                  </p>
                  <p style="font-size: 14px">Email: info@digitalgxp.com</p>
                  <p style="font-size: 14px">© 2020 by Digital GxP</p>
                  <p style="font-size: 14px">
                    17 Jefferson Ave, Lake Hiawatha NJ 07034, USA
                  </p>
                </div>
              </div>
              
            </div>
          </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div style="display: flex; flex-direction: column; align-items: center">
          <div style="width: 90%; align-self: center; background-color: black">
            <div style="padding: 20px; width: 100%">
              <div class="row" style="width: 100%; margin: 10px">
                <div
                  style="
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  font: var(--font_9);
                "
              >
                <div style="width: 70%; padding: 20px; color: whitesmoke">
                  <p style="font-size: 24px">Digital GxP</p>
                  <p style="font-size: 14px">
                    Paperless Digital SOP, Protocol, Batch Records, Forms,
                    Checksheets, Logbooks
                  </p>
                  <p style="font-size: 17px">About Us</p>
                  <p style="font-size: 14px">
                    With a combined industry experience of over 3 decades in
                    SaaS platforms & GxP industry, our aim is to digitalize and
                    drive towards pharma industry 4.0 starting with paperless
                    systems
                  </p>
                  <p style="font-size: 14px">Email: info@digitalgxp.com</p>
                  <p style="font-size: 14px">© 2020 by Digital GxP</p>
                  <p style="font-size: 14px">
                    17 Jefferson Ave, Lake Hiawatha NJ 07034, USA
                  </p>
                </div>
              </div>
              <div
                style="
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  font: var(--font_6);
                "
              >
                <div style="width: 70%; padding: 20px; color: whitesmoke">
                  <p style="font-size: 21px; font-weight: 400">
                    Please drop your email for Support
                  </p>
                  <p style="font-size: 21px; font-weight: 400">
                    <input
                      type="text"
                      v-model="emailForDemo"
                      maxlength="254"
                    /><button
                      id="submitEmailForDemo"
                      @click="dropEmailForDemo()"
                    >
                      Submit
                    </button>
                  </p>
                  <p
                    style="
                      font-size: 16px;
                      font: var(--font_8);
                      color: rgb(var(--color_16));
                    "
                  >
                    We will be in touch soon! Thanks!
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>


      
      <!-- <div
        style="
          background-image: url(/img/brand/gh4.png);
          height: 396px;
          background-size: 100% 100%;
        "
      ></div> -->
    </div>
    <footer></footer>
  </div>
</template>
<script>
// import { SlideYUpTransition } from "vue2-transitions";
import axios from "../utils/axiosinstance";

export default {
  name: "auth-layout",
  components: {
    // SlideYUpTransition,
  },
  beforeMount() {
    if (window.location.href.toLowerCase().includes("/shareddocument")) {
      this.isSharedPage = true;
    }
    else if (window.location.href.toLowerCase().includes("/sharedproject")) {
      this.isSharedPage = true;
    }
    // if (window.location.href.toLowerCase().includes("/sharedpublic")) {
    //   this.isSharedPage = false;
    // }
  },
   computed: {
    // can adjust speeds based on other factors if needed
    TYPE_SPEED() { return this.speed } ,
    DELETE_SPEED(){ return this.deleteSpeed },
    //timeoutSpeed() { return this.TYPE_SPEED * 0.8 },
  },
  mounted() {
    console.log("rrrr");
    this.createCaptcha();
    this.title="";
    this.words=['o Paperless','o Paperless'];
    this.speed=500;
    this.deleteSpeed=166;
    this.start();
  },
  data() {
    return {
      captchaLength: 5,
      captchaCode: [],
      mainCaptcha: '',
      title: String,
      speed: {
        type: Number,
        default: 50000
      },
      deleteSpeed: {
        type: Number,
        default: 166
      },
      words: {
        type: Array,
        default: ['','']
      },
      displayText: [],
      currentWord: "",
      wordIdx: 0,
      isSharedPage: false,
      showLoginModal: true,
      show:false,
      showRegisterModal: false,
      sharedDocID: 0,
      sharedProjectID: 0,
      emailForDemo: null,
      reg: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      model: {
        login: {
          userName: "",
          password: "",
        },
        register: {
          userName: "",
          email: "",
          password: "",
          orgName: "",
          captcha: "",
        },
      },
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  methods: {
    showPassword() {
      var showpassword = document.getElementById("myInput");
      if (showpassword.type === "password") {
        showpassword.type = "text";
      } else {
        showpassword.type = "password";
      }
    },
    createCaptcha() {
      let tempCaptcha = "";
      for (let i = 0; i < this.captchaLength; i++) {
        tempCaptcha += this.getRandomCharacter();
      }
      this.captchaCode = tempCaptcha.split("");
      this.mainCaptcha = tempCaptcha;
    },
    getRandomCharacter() {
      const symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const randomNumber = Math.floor(Math.random() * 36);
      return symbols[randomNumber];
    },
    getFontSize() {
      const fontVariations = [14, 20, 30, 36, 40];
      return fontVariations[Math.floor(Math.random() * 5)];
    },
    getRotationAngle() {
      const rotationVariations = [5, 10, 20, 25, -5, -10, -20, -25];
      return rotationVariations[Math.floor(Math.random() * 8)];
    },
    removeSpaces(string){
      return string.split(' ').join('');
    },
    isValidCaptcha() {
        // var string1 = this.removeSpaces(document.getElementById('cpatchaTextBox').value);
        var string1 = this.removeSpaces(this.mainCaptcha);
        var string2 = this.removeSpaces(this.model.register.captcha);
        var captchaError = document.getElementById("key");
        if (string1 === string2) {
            // document.getElementById("key").innerHTML = "Matched";
          captchaError.textContent = "Matched"
          captchaError.style.color = "green"
          return true;
        }
        else {
            captchaError.textContent = "not Matched. try Again"
            captchaError.style.color = "red"
            this.createCaptcha();
        }
        return false;
    },
    start() {
      if (this.words && this.words.length > 0) {
        this.currentWord = this.words[this.wordIdx].split("");
        this.wordIdx++;
        this.animate = setTimeout(this.type, this.timeoutSpeed);
      }
    },
    type() {
      if (this.currentWord.length > 0) {
        this.displayText.push(this.currentWord.shift());
        // if done typing, then delete
      } else if (this.currentWord.length === 0 && this.displayText.length > 0) {
        this.timeoutSpeed = this.DELETE_SPEED;
        this.displayText.pop();
        // if done typing & deleting
      } else if (
        this.currentWord.length === 0 &&
        this.displayText.length === 0
      ) {
        // change words
        if (this.wordIdx < this.words.length) {
          this.currentWord = this.words[this.wordIdx].split("");
          this.wordIdx++;
          this.timeoutSpeed = this.TYPE_SPEED;
          this.displayText.push(this.currentWord.shift());
        } else {
          // reset
          this.wordIdx = 0;
          this.currentWord = this.words[this.wordIdx].split("");
          this.displayText.push(this.currentWord.shift());
        }
      }
      setTimeout(this.type, this.timeoutSpeed);
    },

    
    dropEmailForDemo() {
      this.emailForDemo = this.emailForDemo.trim();
      if (
        this.emailForDemo == null ||
        this.emailForDemo == "" ||
        !this.reg.test(this.emailForDemo)
      ) {
        window.alert("Please Enter a valid email address!");
      } else {
        let app = this;
        axios
          .post("/api/dropEmailForDemo", {
            email: this.emailForDemo,
          })
          .then(function () {
            // console.log(response);
            // app.showLoginModal = true;
            // app.showRegisterModal = false;
            // app.$router.push("/login");
            app.emailForDemo = "";
            window.alert(
              "Your Email address has been saved for Demo. We will contact you shortly. \n\n-Team Digital GxP"
            );
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
    }
 },
 isMobileandTab() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
    }
 },
    showLoginOnShared(docID) {
      console.log(docID);
      this.sharedDocID = docID;
      this.isSharedPage = false;
      this.showLoginModal = true;
    },
    launchRegister() {
      this.showRegisterModal = true;
      this.showLoginModal = false;
    },
    showLogin() {
      this.isSharedPage = false;
      this.showLoginModal = true;
      this.showRegisterModal = false;
    },
    handleRegister() {
      let app = this;
      var text = this.model.register.email;
      var error = document.getElementById("error");
      var domain = text.substring(text.lastIndexOf("@"));
      if(domain == "@gmail.com" || domain == "@yahoo.com" || domain == "@hotmail.com" || domain == "@outlook.com" || domain == "@aol.com"){
          alert("Enter a valid company email");
          error.textContent = "Enter a valid company email"
          error.style.color = "red"
          error.style.display = "block"
          return;
      }
      if (!this.isValidCaptcha()) {
        error.style.display = "none"
          return;
      }
      axios
        .post("/api/register", 
        {
          userName: this.model.register.userName,
          email: this.model.register.email,
          captcha: this.model.register.captcha,
          secKey: this.model.register.password,
          orgInfo: { orgName: this.model.register.orgName },
        })
        .then(function () {
          // console.log(response);
          app.showLoginModal = true;
          app.showRegisterModal = false;
          // app.$router.push("/login");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleLogin() {
      var that = this;
      axios
        .post("/api/authenticate", {
          userName: this.model.login.userName,
          secKey: this.model.login.password,
        })
        .then(function () {
          // console.log(response);
          // console.log(that.sharedDocID);
          if (that.sharedDocID && that.sharedDocID.length > 1) {
            that.$router.push({
              name: "new",
              // params: { docid: "6e6e160f-e479-4148-a275-4eb0d8b69262" }
              params: { docid: that.sharedDocID, docType: "shared" },
            });
            return;
          } 
          else if (that.$route.query.redirect) {
            that.$router.push(that.$route.query.redirect);
          } else that.$router.push("/dashboard");
          that.showLoginModal = false;
          that.showRegisterModal = false;
        })
        .catch(function (error) {
          if(error.message){
          document.getElementById("errorMessage").innerHTML = "Incorrect username or password.";
          document.getElementById("errorContact").innerHTML = "If issue persists, please kindly contact us.";
          console.log(error.response.data);
          }
          else{
            console.log(error);
          }
        });
    },
  },
};
</script>
<style>
.address-location {
  margin-left: 1.4rem;
  margin-bottom: 6rem;
  margin-top: 6rem;
}
.login-heading{
  text-align: center;
  color: #fff;
  margin-left: -110px;
  font-size: 20px;
  margin-bottom: -18px;
}
.btn.btn-register{
  border-radius: 20px;
  padding: 12px;
  border: 1px transparent;
  width: 170px;
}
.np-captcha-container {
  background: #eee;
  width: 300px;
  text-align: center;
  display: inline-block;
  margin-bottom: 20px;
}
.np-captcha {
  font-size: 24px;
}
.np-button {
  padding: 6px 10px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  font-size: 16px;
}
.np-captcha-character {
  display: inline-block;
  letter-spacing: 14px;
}
.inline{
  display: inline-block;
  margin-left: 30px;
}
#cpatchaTextBox{
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
a.req-demo {
  color: #525f7f;
}
a.req-demo:hover {
  color: white;
  background-color: #525f7f;
  border-color: #525f7f;
}
:root {
  --font_2: normal normal normal 40px/1.4em futura-lt-w01-book, sans-serif;
  --font_3: normal normal normal 21px/1.4em futura-lt-w01-book, sans-serif;
  --font_6: normal normal bold 18px/1.4em futura-lt-w01-book, sans-serif;
  --font_7: normal normal normal 18px/1.4em futura-lt-w01-book, sans-serif;
  --font_8: normal normal normal 16px/1.4em proxima-n-w01-reg, sans-serif;
  --font_9: normal normal normal 14px/1.4em proxima-n-w01-reg, sans-serif;
  --color_12: 242, 242, 242;
  --color_16: 177, 211, 187;
  --color_33: 74, 155, 247;
  --bg-overlay-color: rgb(var(--color_12));
}
body {
  background-color: white !important;
}
.usp-img {
  height: 500px;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size: 100% auto;
  background-image: url("/img/pptx/homepage/usp_v1.png");
}

.footer-img {
  height: 185px;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;
  background-size: 100% auto;
}


</style>
